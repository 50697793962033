exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2013-10-25-running-oracle-sql-plus-from-gradle-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2013-10-25-running-oracle-sql-plus-from-gradle/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2013-10-25-running-oracle-sql-plus-from-gradle-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2017-06-09-using-webpack-instead-of-webjars-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2017-06-09-using-webpack-instead-of-webjars/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2017-06-09-using-webpack-instead-of-webjars-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2019-07-10-transfer-a-docker-image-without-a-registry-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2019-07-10-transfer-a-docker-image-without-a-registry/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2019-07-10-transfer-a-docker-image-without-a-registry-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2020-01-04-managed-zio-environments-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2020-01-04-managed-zio-environments/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2020-01-04-managed-zio-environments-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2020-08-01-measuring-bidirectional-zstream-latency-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/2020-08-01-measuring-bidirectional-zstream-latency/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2020-08-01-measuring-bidirectional-zstream-latency-index-mdx" */)
}

